import React, { useState, useEffect, useRef } from 'react';

import HeaderBar from '../../components/header-bar';
import BottomSheet from '../../components/bottom-sheet';
import Button from '../../components/button';
import WebFn, { callAppFunc } from '../../utils/jsbridge/index.js';
import { encode, decode } from 'js-base64';
import { useAPI } from '../../utils/api';
import { showLoading, dismissLoading } from '../../utils/loading';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../../redux/store';
import { navigate } from 'gatsby';

const PIN_LENGTH = 6;
const KEYCODE = Object.freeze({
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  END: 35,
  HOME: 36,
  SPACE: 32,
});

const ValidInfo = () => {
  const api = useAPI();
  const accessToken = useSelector((state) => getters.getAccessToken(state));
  const [mobile, setMobile] = useState('');
  const [value, setValue] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchMemberData', {});
  }, []);

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendMemberData') {
      const dataJsonString = decode(dataJson.params.data);
      const jsonData = JSON.parse(dataJsonString);
      setMobile(jsonData.mobile);
    }
  };

  const sleep = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  };

  const submit = (event) => {
    event.preventDefault();
    showLoading();
    api
      .validCode({ mobile: mobile, code: value })
      .then((res) => {
        //dismissLoading();
        if (!res) {
          //callback ? callback(false) : undefined;
          return;
        }
        window.popup('刪除成功');
        //callback ? callback(true) : undefined;

        deleteMember();
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  const deleteMember = () => {
    api
      .deleteMember({ accessToken })
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }
        callAppFunc('doLogout', {});

        //callAppFunc('backHome', {});
      })
      .catch((error) => {
        dismissLoading();
        alert(error);
        console.log(error);
      });
  };

  const resend_validcode = (event) => {
    event.preventDefault();
    showLoading();
    api
      .sendValidCode({ mobile: mobile })
      .then((res) => {
        if (!res) {
          return;
        }
        dismissLoading();
        alert('已重新傳送驗證碼!');
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  function handleClick(e) {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleChange(e) {
    const val = e.target.value || '';
    if (val.length === 6) {
      inputRef.current.blur();
    }
    setValue(val);
  }

  // 設定鍵盤的行為
  function handleOnKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODE.LEFT_ARROW:
      case KEYCODE.RIGHT_ARROW:
      case KEYCODE.HOME:
      case KEYCODE.END:
      case KEYCODE.SPACE:
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <HeaderBar title="OTP驗證" goBack={() => navigate(-1)} />
      <form onSubmit={submit}>
        <div className={'container'}>
          <p className={'ptitlestyle'}>請輸入傳送到{mobile}的6位驗證碼</p>
          <input
            ref={inputRef}
            className={'hiddenInput'}
            type="number"
            pattern="\d*"
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            maxLength={PIN_LENGTH}
          />
          {Array.from({ length: PIN_LENGTH }).map((_, index) => {
            const focus =
              index === value.length ||
              (index === PIN_LENGTH - 1 && value.length === PIN_LENGTH);
            return (
              <input
                className={`pinInput ${focus ? 'fucos' : ''}`}
                key={index}
                value={value[index] || ''}
                onClick={handleClick}
                readOnly={true}
              />
            );
          })}
          <p className={'plinkstyle'} onClick={resend_validcode}>
            重新傳送驗證碼
          </p>
        </div>

        <BottomSheet persistant noTitle noRounded>
          <Button
            text="確認"
            type="submit"
            disabled={value.length === 6 ? false : true}
          />
        </BottomSheet>
      </form>
      <style jsx>
        {`
          .container {
            padding: 24px 20px;
            background-color: white;
            padding-bottom: 120px;
            height: 100%;
          }
          .hiddenInput {
            width: 0;
            height: 0;
            outline: 'none';
            padding: 0;
            border-width: 0;
            box-shadow: 'none';
            position: 'absolute';
          }

          .pinInput {
            box-sizing: border-box;
            padding: 0;
            outline: none;
            background-color: #e2e2e2;
            width: 39px;
            height: 40px;
            margin: 10px 9px 20px;
            text-align: center;
            border: 1px solid rgba(189, 189, 189, 0.932);
            border-radius: 3px;
            font-size: 25px;
            font-weight: 500;
          }

          .fucos {
            border-color: rgba(189, 189, 189, 0.932);
            border-width: 2px;
          }
          .ptitlestyle {
            font-size: 20px;
            font-weight: 500;
          }
          .plinkstyle {
            color: gray;
          }
        `}
      </style>
    </>
  );
};

export default ValidInfo;
